import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { GetUTCTime } from "../../../Helpers/GetUTCTime";
import { UpdateTemplateDataSpecific } from "../../../Helpers/ApiCall";
import { useEffect } from "react";
import { useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import { filterPdfTemplateData } from "../../../Helpers/HelperFunctions";

function RadioElement({ element, elementType, templateId, pdfCategoryData, level, pdfCategoriesType }) {
  const [radioValue, setRadioValue] = useState();
  const storeData = useSelector((state) => state.CommonReducer);
  const dispatch = useDispatch();

  let templateIndex = storeData?.inspectionData?.data?.inspectiontemplates?.findIndex((itm) => itm.template_id === templateId);
  let prevData = JSON.parse(JSON.stringify(storeData?.inspectionData?.data));
  delete prevData?.report_media_library_files;   
  let pdfTemplate = prevData?.inspectiontemplates[templateIndex];
  let mergeId = element?.merge_id;
  let ElementpdfData = pdfTemplate?.pdf_data?.filter((item) => item?.merge_id == mergeId);
  let newPrevData = JSON?.parse(JSON?.stringify(storeData?.inspectionData?.data));
  let newPdfTemplate = newPrevData?.inspectiontemplates[templateIndex];

  const HandleUpdateRadioApiCall = async (radio) => {
    let formData = {
      type: "Radio",
      merge_id: mergeId,
      value: radio,
      updated_at: await GetUTCTime(),
    };
    // if (
    //   pdfTemplate?.pdf_data?.length !== 0 &&
    //   pdfTemplate?.pdf_data?.length !== undefined
    // ) {
    //   pdfTemplate.pdf_data.length = 0;
    // }
    pdfTemplate?.pdf_data?.push(formData);
    newPdfTemplate?.pdf_data?.push(formData);
    let filteredData = pdfTemplate?.pdf_data?.filter((el) => el?.merge_id != mergeId);
    filteredData.push(formData);
    let filteredObject = filterPdfTemplateData(prevData, templateIndex, filteredData, mergeId);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
  };

  useEffect(() => {
    // if (ElementpdfData?.[0]?.value) {
    let value = ElementpdfData?.[0]?.value;
    setRadioValue(value == undefined && value == null ? "" : value);
    // }
  }, [ElementpdfData?.[0]?.value]);

  return (
    <>
      <Grid container className={`${elementType === "inner" || elementType === "layout" ? "pdf_container" : "pdf_inner_container"}`}>
        <Grid item xs={12}>
          <FormControl variant="standard" className={`forms-control w-100 ${level == 3 ? "level3_class" : ""}`}>
            <label
              className={
                pdfCategoriesType == 1
                  ? (elementType !== "inner" && elementType !== "layout") || radioValue == "" || radioValue == undefined
                    ? "inner_details_title"
                    : "inner_details_title_after_selection"
                  : "inner_details_title"
              }
              id="categoryName"
            >
              {pdfCategoriesType == 1 ? (
                (elementType !== "inner" && elementType !== "layout") || radioValue == "" || radioValue == undefined ? (
                  ""
                ) : (
                  <DoneIcon sx={{ marginLeft: "-28px", position: "absolute" }} />
                )
              ) : (
                ""
              )}
              {element?.label}
            </label>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              onChange={(e) => {
                setRadioValue(e.target.value);
                HandleUpdateRadioApiCall(e.target.value);
              }}
            >
              <Grid container spacing={2} sx={{ marginTop: "2px" }}>
                {element?.elements?.map((item, index) => (
                  <Grid item xs={12} md={3} key={index}>
                    <FormControlLabel
                      className="radio_button radio_icon"
                      key={index}
                      value={item}
                      control={
                        <Radio
                          sx={{
                            height: "19.5px !important",
                            width: "19.5px !important",
                            marginRight: "10px",
                            "& .MuiSvgIcon-root": { fontSize: "19.5px !important" },
                          }}
                          checked={item === radioValue}
                        />
                      }
                      label={item}
                      style={{ display: "flex", alignItems: "flex-start" }}
                    />
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default RadioElement;
